import styled from 'styled-components';
import colors from '../../styles/colors';

export const Block = styled.div`
  display: flex;
  height: fit-content;
  flex-direction: column;
  margin-bottom: 30px;

  @media screen and (max-width: 600px) {
    align-items: center;
    text-align: center;
  }
`;

export const Title = styled.h3`
  color: ${colors.texts.black};
  margin-bottom: 18px;

  @media screen and (max-width: 600px) {
    margin-bottom: 20px;
  }
`;

export const Info = styled.p`
  color: ${colors.texts.darkBlue};
  white-space: pre-line;
`;