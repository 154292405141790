import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next'
import {
  Block,
  Content,
  TextSide,
  TitleContainer,
  Title,
  Container
} from './StyledElements'
import LegalTxt from '../../Blocks/LegalTxt'

const LegalContainer = () => {
  const { t } = useTranslation();

  return (
    <Block>
      <Content>
        <TextSide>
          <TitleContainer>
            <Title>{t('LegalNo')}</Title>
          </TitleContainer>
        </TextSide>
        <Container>
          <LegalTxt 
            titleLegal={t('Editor')}
            infoLegal= {t('EditorTxt')}
          />
          <LegalTxt 
            titleLegal={t('Hosting')}
            infoLegal= {t('HostingTxt')}
          />
          <LegalTxt 
            titleLegal={t('CreditsLegal')}
            infoLegal= {t('CreditsTxt')}
          />
          <LegalTxt 
            titleLegal={t('Relevance')}
            infoLegal= {t('RelevanceTxt')}
          />
          <LegalTxt 
            titleLegal={t('Website')}
            infoLegal= {t('WebsiteTxt')}
          />
          <LegalTxt 
            titleLegal={t('Respect')}
            infoLegal= {t('RespectTxt')}
          />
          <LegalTxt 
            titleLegal={t('Hypertext')}
            infoLegal= {t('HypertextTxt')}
          />
          <LegalTxt 
            titleLegal={t('CollectLegal')}
            infoLegal= {t('CollectTxt')}
          />
          <LegalTxt 
            titleLegal={t('Uses')}
            infoLegal= {t('UsesTxt')}
          />
          <LegalTxt 
            titleLegal={t('GDPR')}
            infoLegal= {t('GDPRTxt')}
          />
          <LegalTxt 
            titleLegal={t('Cookies')}
            infoLegal= {t('CookiesTxt')}
          />
        </Container>       
      </Content>
    </Block>
  )
}

LegalContainer.propTypes = {

};

LegalContainer.defaultProps = {

};

export default LegalContainer;