import * as React from "react"
import Navbar from '../components/Navbar';
import Footer from "../components/Footer"
import '../styles/font.css';
import './layout.css';
import HeaderHtml from '../components/HeaderHtml';
import { graphql } from 'gatsby';
import LegalContainer from '../Containers/LegalContainer';
import colors from "../styles/colors";

const Legal = () => {
  return (
    <>
      <HeaderHtml page="legal"/>
      <Navbar type="light" />
      <LegalContainer />
      <Footer background={colors.blocks.white} color={colors.texts.black}/>
    </>
  )
}

export default Legal;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;