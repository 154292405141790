import styled from 'styled-components'
import colors from '../../styles/colors'

export const Block = styled.div`
  background: ${colors.blocks.white};
  width: 100%;
  height: fit-content;
  padding: 100px 37px 70px;
  box-sizing: border-box;
  

  @media screen and (max-width: 400px) {
    padding: 64px 20px;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(10px, 1fr));
  grid-template-rows: auto;
  gap: 0 1rem;
  max-width: 660px;
  margin: 0 auto;
  justify-content: column;
`;

export const TextSide = styled.div`
  grid-column: 3 / span 8;
  margin-bottom: 100px;
  @media screen and (max-width: 400px) {
    grid-column: span 12;
    margin-bottom: 64px;
  }
`;

export const TitleContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
`;

export const Title = styled.h1`
  color: ${colors.titles.darkBlue};
  margin: 0 0 30px 0;
`;

export const PartnersImg = styled.img`
  width: 100%;
`;

export const Container = styled.div`
  grid-column: span 12;

`;